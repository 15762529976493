<template>
  <div v-if="contact && contact.phone">
    <a :href="'tel:' + contact.phone">
      <b-badge class="icon-badge text-center badge-link"><fa icon="phone"/></b-badge>
      {{contact.name ? contact.name : 'Contact'}}
    </a>
  </div>
</template>

<script lang="ts">
  import {Prop, Vue, Component} from "vue-property-decorator";
  import {Contact} from "@eguide/api";

  @Component
  export default class ContactProperty extends Vue {
    @Prop({required: true}) contact:Contact
  }
</script>
